import { useState, useEffect } from 'react';
import './MainButton.css';
import { useAnimate} from "framer-motion"

interface Props {
  text: string,
  hoverText: string,
  loadingText?: string,
  handler?: (params?: any) => void,
  disabled?: boolean,
  loading?: boolean,
  stickyOnMobile?: boolean,
  recaptchaKey?: string,
  typeSubmit?: boolean,
  textButton?: boolean
}

export default function MainButton(props: Props) {
  const {
    text,
    hoverText,
    handler,
    disabled,
    loading,
    stickyOnMobile,
    recaptchaKey,
    typeSubmit,
    textButton,
    loadingText
  } = props;

  const [scope, animate] = useAnimate();
  const [dots, setDots] = useState('');

  useEffect(() => {
    if (loading) {
      animate(scope.current, { scale: 1.07 }, { duration: 2, repeat: Infinity, ease: [0.5, 0, 1, 0.1] });
      const interval = setInterval(() => {
        setDots(prev => (prev.length >= 3 ? '' : prev + '.'));
      }, 500);
      return () => clearInterval(interval);
    }
  }, [loading]);

  if(textButton) {
    return  (
      <div className="button-wrap large-btn text-button">
        <a onClick={handler}>
          <div className="button-text">
            <span data-hover={hoverText}>{text}</span>
          </div>
        </a> 
      </div>
    )
  }

  return (
    <div className={`button-box ${stickyOnMobile ? 'sticky-on-mobile' : ''}`}>
      <div className="clapat-button-wrap">
        <div ref={scope} className={`clapat-button ${(loading || disabled) && 'button-disabled'}`}>
          <button 
            className="button-border rounded outline"
            onClick={handler}
            disabled={disabled || loading}
            type={typeSubmit ? 'submit' : 'button'}
          >
            <span data-hover={loading ? loadingText : hoverText}>
              {loading ? (
                <b className="loading-text">
                  {loadingText} <i className="dots">{dots}</i>
                </b>
              ) : text}
            </span>
          </button>
        </div>
      </div> 
    </div>
  );
}