import { useTsController } from "@ts-react/form";

interface Props {
  placeholder?: string
  defaultValue?: string
}

export function FormTextField(props: Props) {
  const {placeholder, defaultValue} = props
  const {
    field: { onChange, value },
    error,
  } = useTsController<string>();
  return (
    <>
      <div className="input-box"> 
        <input 
          size={30}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          placeholder={placeholder}
        />
        <label className="input_label"></label>
        <div className="alert-error">{error?.errorMessage && error.errorMessage}</div>
      </div> 
    </>
  );
}