import { defaultConfig } from '@web3modal/ethers/react';
import { baseSepolia, baseMainnet } from './chain-configs';

// 1. Get projectId
const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID!
const metadataUrl = process.env.REACT_APP_WALLET_CONNECT_METADATA_URL!

// 2. Set chains
//import required chains from ./configs/chain-configs'

// 3. Create a metadata object
const metadata = {
  name: 'Phygital Mining',
  description: 'Token-generating fashion collections',
  url: metadataUrl, // origin must match your domain & subdomain
  icons: []
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  //rpcUrl: '...', // used for the Coinbase SDK
  //defaultChainId: 1 // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
export const we3modalConfig = {
  ethersConfig,
  chains: [baseMainnet],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
}