import React from 'react';
import tgIcon from '../images/telegram_icon.png';

const TgButton = ({ text, url, icon }: { text: string; url: string, icon: boolean; }) => {
  return (
    <div className="button-box">
      <div className="clapat-button-wrap">
        <div className="clapat-button">
          <a
            className="button-border rounded outline"
            href={url}
            target='blank'
          >
            {icon && <img src={tgIcon} height="46" width="46" alt="telegram icon" />}
            <small><span data-hover={text}>
              {text}
            </span>
            </small>
          </a>
        </div>
      </div> 
    </div>
  );
}

export default TgButton;