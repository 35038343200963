import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react';
import { useState, useEffect } from 'react';
import { BrowserProvider, ethers, Contract } from 'ethers';
import { whitelist } from '../configs/whitelist';
import PhygitalCollectionABI from '../configs/PhygitalCollectionABI.json';
import ConnectButton from './ConnectButton';
import MainButton from './MainButton';
import TgButton from './TgButton';

const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS!
const MINT_START_DATE = '2024-10-08T10:00:00';

export function MintNft(props: any) {
  const {connectedContract, nftInfo, getNFTInfo} = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isAddressTrusted, setIsAddressTrusted] = useState(false);
  const [inProcess, setInProcess] = useState(false);
  const [totalMinted, setTotalMinted] = useState<string>('0');
  const [maxSupply, setMaxSupply] = useState<string>('0');
  const [countdown, setCountdown] = useState<string>('');
  const [saleEndTimestamp, setSaleEndTimestamp] = useState<number>(0);
  const [isMintStarted, setIsMintStarted] = useState(false);
  const [mintCountdown, setMintCountdown] = useState<string>('');
  const [mintLive, setMintLive] = useState<string>('');
  
  const [currentPrice, setCurrentPrice] = useState<string>('');

  const { address, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const fetchPrice = async (contract: Contract) => {
    try {
      let price = await contract.getCurrentPriceInEth();
      const saleInfo = await contract.saleParameters();

      const currentTime = Math.floor(Date.now() / 1000);
      if (currentTime < saleInfo.saleStart) {
        throw new Error("Sale is not started yet");
      }
      if (currentTime > saleInfo.saleEnd) {
        throw new Error("Sale has ended");
      }
      setSaleEndTimestamp(Number(saleInfo.saleEnd));

      const totalSupply = await contract.totalSupply();
      const currentSupply = await contract.totalSupply();
      setTotalMinted(currentSupply.toString());
      setMaxSupply(saleInfo.maxSupply.toString());
      if (totalSupply >= saleInfo.maxSupply) {
        throw new Error("All Packs have been minted");
      }

      const isFreeMint = totalSupply < saleInfo.cutoffNumber;
      price = isFreeMint ? ethers.parseEther("0") : price;

      setCurrentPrice(ethers.formatEther(price));
    } catch (error) {
      console.error('Failed to fetch price:', error);
      setErrorMessage('Failed to fetch current price');
    }
  };

  const updateCountdown = () => {
    const now = Math.floor(Date.now() / 1000);
    const timeLeft = saleEndTimestamp - now;

    if (timeLeft <= 0) {
      setCountdown('Sale has ended');
      return;
    }

    const days = Math.floor(timeLeft / (24 * 60 * 60));
    const hours = Math.floor((timeLeft % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((timeLeft % (60 * 60)) / 60);
    const seconds = timeLeft % 60;

    setCountdown(`${days} days ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
  };

  // Helper function to format the price for display
  const formatPrice = (price: string): string => {
    return Number(price).toFixed(4);
  };
  // Helper function to get a user-friendly error message
  const getUserFriendlyErrorMessage = (error: any): string => {
    if (typeof error === 'string') {
      if (error.includes('user rejected') || error.includes('User denied')) {
        return "Transaction canceled by user.";
      } 
    } else if (error instanceof Error) {
      if (error.message.includes('user rejected') || error.message.includes('User denied')) {
        return "Transaction canceled by user.";
      }
      if (error.message.includes('estimateGas')) {
        return "Not enought ETH to pay for a gas. Please top up your wallet.";
      }
    } 

    // Add more specific error checks here if needed
    return "An error occurred while processing your transaction. Please try again.";
  };

  const shortenAddress = (address: string): string => {
    if (address && address.startsWith('0x') && address.length === 42) {
      return `${address.substring(0, 5)}...${address.substring(39)}`;
    }
    return address;
  };

  useEffect(() => {
    const setup = async () => {
      if (isConnected && walletProvider) {
        checkWhitelist(address);
        if (connectedContract) {
          await fetchPrice(connectedContract);
        }
      }
    };
    setup();
  }, [isConnected, walletProvider, address, connectedContract]);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => setSuccessMessage(''), 10000); // Clear after 5 seconds
      return () => clearTimeout(timer);
    }
    if (errorMessage) {
      const timer = setTimeout(() => setErrorMessage(''), 10000); // Clear after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [successMessage, errorMessage]);

  const updateMintCountdown = () => {
    const now = new Date();
    const nyTime = new Date(now.toLocaleString("en-US", {timeZone: "America/New_York"}));
    const mintStartTime = new Date(MINT_START_DATE);
    const timeLeft = mintStartTime.getTime() - nyTime.getTime();

    if (timeLeft <= 0) {
      setIsMintStarted(true);
      setMintLive('Mint is Live!');
      return;
    }

    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    setMintCountdown(`${days} days ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} left`);
  };

  useEffect(() => {
    updateMintCountdown();
    const timer = setInterval(updateMintCountdown, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (saleEndTimestamp > 0) {
      updateCountdown(); // Initial update
      const timer = setInterval(updateCountdown, 1000);
      return () => clearInterval(timer);
    }
  }, [saleEndTimestamp]);

  const mintNFT = async () => {
    if (!connectedContract) return;
    setInProcess(true);
    try {
      setErrorMessage('');
      setSuccessMessage('');

      const merkleProof: string[] = [];
      // Parse the current price to wei
      const priceInWei = ethers.parseEther(currentPrice);

      const tx = await connectedContract.buy(merkleProof, false, { value: priceInWei });
      await tx.wait();

      // setSuccessMessage('Pack minted successfully! 🎉');
      console.log('%c NFT purchased successfully ', 'background: green; color: white');

      await getNFTInfo();
      
    } catch (error) {
      const userFriendlyMessage = getUserFriendlyErrorMessage(error);
      setErrorMessage(userFriendlyMessage);
      console.error('Failed to buy Pack:', error);
    } finally {
      setInProcess(false);
    }
  };

  const checkWhitelist = (address: string | undefined) => {
    if (!address) {
      setIsAddressTrusted(true);
      return;
    }
    if (whitelist.length > 0) { 
      setIsAddressTrusted(!!whitelist.find(trustedAddress => trustedAddress === address));
    } else {
      setIsAddressTrusted(true); // Or false, depending on your default behavior
    }
  };

  useEffect(() => {
    const checkMintStart = () => {
      const now = new Date();
      const nyTime = new Date(now.toLocaleString("en-US", {timeZone: "America/New_York"}));
      const mintStartTime = new Date(MINT_START_DATE);
      
      // console.log("Current NY time:", nyTime);
      // console.log("Mint start time:", mintStartTime);
      
      setIsMintStarted(nyTime >= mintStartTime);
    };

    checkMintStart();
    const timer = setInterval(checkMintStart, 1000); // Check every second

    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      {errorMessage && (
        <div className="alert alert-error mb-4">{errorMessage}</div>
      )}
      {successMessage && (
        <div className="alert alert-success mb-4">{successMessage}</div>
      )}
  
      <div>
        {!isConnected ? (
          <>
            {!isMintStarted ? (
              <>
                <h4>Mint starts on October 8 <br />10 AM New York time</h4>
                <p>{mintCountdown}</p>
              </>
            ) : (
              <>
                {mintLive && (
                  <h3>The sale has ended</h3>
                )}
                {/* <ConnectButton type='connect' stickyOnMobile/> */}
              </>
            )}
          </>
        ) : (
          <>
            <span>Sale ends in {countdown}</span><br /><br />
            <span>Minted: {totalMinted} of {maxSupply}</span><br />
            <span>Price: {formatPrice(currentPrice)} ETH ($100)</span><br />
            <hr/>
            {isAddressTrusted ? (
              <>
                <MainButton
                  text='Mint Now'
                  hoverText='Mint Now'
                  loadingText="Minting"
                  handler={mintNFT}
                  loading={inProcess}
                  stickyOnMobile
                />
              </>
            ) : (
              <div className="alert alert-error">Your address is not on a whitelist</div>
            )}
          </>
        )}
      
        <br /><br />
        {(isConnected && nftInfo && +nftInfo.tokens > 0) && (
          <small>You own {nftInfo.tokens} pack(s), IDs: {nftInfo.tokenIds.join(', ')}</small>
        )}
  
        {isConnected && (
          <>
            <br />
            <small>Connected wallet {shortenAddress(address || '')}</small>
            <p className='small'>
              <ConnectButton type='disconnect' textButton/>
            </p>
          </>
        )}
        <br />
        <TgButton text="Discuss in Telegram" url="https://t.me/+UfM7vFbvRk0wNTli" icon={false} /><br />
      </div>
    </div>
  );
}