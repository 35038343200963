import { useCallback, useEffect, useState } from "react"
import MainButton from "./MainButton"
import { z } from "zod";
import { Form } from "./FormComponent";
import Countries from "../configs/countries.json"


const countries = Countries.map((c)=>c.name)
const API_URL = process.env.REACT_APP_BACKEND_API!
const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS!

const ShippingAddressSchema = z.object({
  // name: z.string().regex(/^[A-Z][a-z]*(([,.] |[ '-])[A-Za-z][a-z]*)*(\.?)$/, 'Enter a valid name'),
  name: z.string().min(1, 'Please enter a valid name'),
  email: z.string().email("Please eEnter a real email address"),
  street: z.string().min(1, 'Please enter a valid street'),
  city: z.string().min(1, 'Please enter a valid city'),
  state: z.string().min(1, 'Please enter a valid state'),
  zip: z.string().min(1, 'Please enter a valid zip'),
  phone: z.string().min(1, 'Please enter a valid phone number'),
  telegram: z.string().min(1, 'Please enter a telegram'),
  // street: z.string().min(3, 'Enter a valid street name'),
  // city: z.string().min(3, 'Enter a valid city name'),
  // state: z.string().min(2, 'Enter a valid state name'),
  // zip: z.string().regex(/^\d{5}(?:[-\s]\d{4})?$/, 'Enter a valid zip code'),
  country: z.enum(["country", ...countries]), // renders DropDownSelect and passed the enum values
  // phone: z.string().regex(/[0-9]{10}/, 'Enter a real phone number please'),
});

export function ShippingAddressForm (props: any) {
  const {nftInfo, toggleSteps, forceShowStep, setForceShowStep, jwtToken, setStep, setReauthorizeNeeded} = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentAddress, setCurrentAddress] = useState<any>(undefined)


  const onSubmit = async (data: z.infer<typeof ShippingAddressSchema>) => {
    setLoading(true);
    try{
      // gets typesafe data when form is submitted
      const body ={
        "address": {
            "name": data.name,
            "email": data.email,
            "street": data.street,
            "city": data.city,
            "state": data.state,
            "zip": data.zip,
            "country": data.country,
            "phone": data.phone,
            "telegram": data.telegram,
            "collection": CONTRACT_ADDRESS
        },
      }

      const res = await fetch(`${API_URL}/address/submit`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        },
        body: JSON.stringify(body),
      });
      const response = await res.json()
      if(res.ok){
        setForceShowStep(false);
        setStep(3);
      }else{
        setErrorMessage(`Submitting address failed: ${res.status === 401 ? ' Unauthorized' : response.message}`);
        setTimeout(()=>{setErrorMessage('')},6000);
      }
    }
    catch (err: any){
      console.error('Submitting address failed: ' + err.message)
      setErrorMessage('Submitting address failed: ' + err.message);
      setTimeout(()=>{setErrorMessage('')},5000);
    }
    finally{setLoading(false)}
  }

  const getAddress = useCallback(async () =>{
    const res = await fetch(`${API_URL}/address/get`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwtToken}`
      },
    });
    const response = await res.json()
    if(res.ok){
      setCurrentAddress(await response.address.address)
    }else{
      setErrorMessage(
        `${res.status === 401 ?
          res.statusText + ' Please confirm authorization in your wallet' :
          ''}`
      );
      if(res.status === 401)setReauthorizeNeeded(true);
      setTimeout(()=>{setErrorMessage('')}, 6000);
    }
  },[jwtToken])

  useEffect(()=>{getAddress()},[getAddress]);

  if(nftInfo && +nftInfo.tokens > 0) return (
    
        <>
          <div className="dark-section">
              <p>
              Important: Please enter your shipping address within 7 days to receive your phygital item. Failure to provide a valid address may result in forfeiture of the item.
              </p>
              <hr />
            <Form
              key={currentAddress?.name}
              schema={ShippingAddressSchema}
              onSubmit={onSubmit}
              defaultValues={{
                country:  currentAddress?.country,
                name: currentAddress?.name,
                email:currentAddress?.email,
                city: currentAddress?.city,
                zip: currentAddress?.zip,
                phone: currentAddress?.phone,
                street: currentAddress?.street,
                state: currentAddress?.state,
                telegram: currentAddress?.telegram,
              }}
              renderAfter={() => (
                <>
                  <div className="content-row">
                    <MainButton 
                      text={"Submit address"}
                      hoverText={"Submit address"}
                      loading={loading}
                      loadingText={"Processing"}
                      typeSubmit
                    />
                    {!forceShowStep &&
                      <>
                        <span className="align-self-center sm-mt-16">&nbsp;&nbsp; or &nbsp;</span>
                        <MainButton 
                          text={'Mint more NFTs'}
                          hoverText={'Mint more NFTs'}
                          handler={toggleSteps}
                          loadingText={"Please wait"}
                          textButton
                        />
                        
                      </>
                    }
                  </div>
                  <hr />
                </>
              )}
              // optional typesafe props forwarded to your components
              props={{
                country: {
                  options: countries,
                  defaultValue: currentAddress?.country
                },
                name: {
                  placeholder: "First and Last name",
                  defaultValue: currentAddress?.name
                },
                email: {
                  placeholder: "Email",
                  defaultValue: currentAddress?.email
                },
                city: {
                  placeholder: "City",
                  defaultValue: currentAddress?.city
                },
                zip: {
                  placeholder: "Zip Code",
                  defaultValue: currentAddress?.zip
                },
                phone: {
                  placeholder: "Phone number",
                  defaultValue: currentAddress?.phone
                },
                street: {
                  placeholder: "Street address",
                  defaultValue: currentAddress?.street
                },
                state: {
                  placeholder: "State",
                  defaultValue: currentAddress?.state
                },
                telegram: {
                  placeholder: "Telegram",
                  defaultValue: currentAddress?.telegram
                },
                
              }}
            >
              {({name, email, street, city, zip, country, phone, state, telegram})=>{
                return (
                  <>
                    <div className="dark-section">
                        {name}
                        {email}
                        {street}
                      <div className="content-row">
                        <div className="one_half">
                          {city}
                        </div>
                        <div className="one_half last">
                          {state}
                        </div>
                      </div>
                      <div className="content-row">
                        <div className="one_half">
                        {country}
                        </div>
                        <div className="one_half last">
                          {zip}
                        </div>
                      </div>
                        
                      <div className="content-row">
                        <div className="one_half">
                          {telegram}
                        </div>
                        <div className="one_half last">
                          {phone}
                        </div>
                      </div>
                    </div>
                    {errorMessage &&
                      <>
                        <hr />
                        <div className="alert-error">{errorMessage}</div>
                      </>
                    }
                    {/* {successMessage &&
                      <>
                        <hr />
                        <div className='text-green'>{successMessage}</div>
                      </>
                    } */}
                    <hr />
                  </>
                )
              }}
            </Form>
          </div>
        </>
    
  )
  return null
}