import { createTsForm, createUniqueFieldSchema } from "@ts-react/form";
import { z } from "zod";
import { FormSelect } from "./fields/FormSelect";
import { FormTextField } from "./fields/FormTextField";

const PhoneSchema = createUniqueFieldSchema(
  z.string().regex(/[0-9]{10}/),
  "phone"
);

// create the mapping
const mapping = [
  [z.enum([""]) , FormSelect],
  [z.string() , FormTextField],
  // [[PhoneSchema, PhoneField]],
  // [z.boolean(), CheckBoxField],
  // [z.number(), NumberField],
] as const; // 👈 `as const` is necessary

// A typesafe React component
export const Form = createTsForm(mapping);