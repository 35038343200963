import './App.css';
import { MintNft } from './components/MintNft';
import { createWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider} from '@web3modal/ethers/react';
import { we3modalConfig } from './configs/web3modal-config';
import packImage from './images/pack.png';
import { ShippingAddressForm } from './components/ShippingAddressForm';
import { BrowserProvider, Contract } from 'ethers';
import PhygitalCollectionABI from './configs/PhygitalCollectionABI.json';
import { useCallback, useEffect, useRef, useState } from 'react';
import MainButton from './components/MainButton';
import Cookies from 'js-cookie';
import Confetti from 'react-confetti';
import TgButton from './components/TgButton';
import { motion } from "framer-motion";
import { baseSepolia, baseMainnet } from './configs/chain-configs';


//Create a Web3Modal instance
createWeb3Modal(we3modalConfig);

const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS!;
const API_URL = process.env.REACT_APP_BACKEND_API!;
const cookiesOptions = {
  secure: true,
  sameState: 'strict',
  expires: 1/24
}

function App() {

  const { isConnected, address, chainId } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const [jwtToken, setJwtToken] = useState('');
  const [signature, setSignature] = useState('');
  const [connectedContract, setConnectedContract] = useState<Contract | undefined>(undefined);
  const [nftInfo, setNftInfo] = useState<{ tokens: string, tokenIds: any[] } | undefined>(undefined);
  const [forceShowStep, setForceShowStep] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [step, setStep] = useState<number | undefined>(1);
  const [isButtonShow, setIsButtonShow] = useState(true);
  const [reauthorizeNeeded, setReauthorizeNeeded] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const formSection = useRef<HTMLDivElement | null>(null);
  const authAttemptedRef = useRef(false);

  const authenticateUser = useCallback(async () => {
    if (authAttemptedRef.current) return;
    if(isConnected && !jwtToken){
      authAttemptedRef.current = true;
      try {
        // Step 1: Get nonce
        const nonceResponse = await fetch(`${API_URL}/auth/generate-nonce`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ address })
        });
        const { nonce } = await nonceResponse.json();

        // Step 2: Sign nonce
        const provider = new BrowserProvider(walletProvider!)
        const signer = await provider.getSigner()
        const signature = await signer.signMessage(nonce);

        // Step 3: Verify signature
        const verifyResponse = await fetch(`${API_URL}/auth/verify-signature`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ address, signature })
        });
        const { token } = await verifyResponse.json();
        
        setJwtToken(token);
        setSignature(signature);
        Cookies.set('jwtt', token, cookiesOptions)
        return {token, signature};
      } catch (err: any) {
        setErrorMessage('Authentication failed: ' + err.message);
        setTimeout(()=>{setErrorMessage('')},5000);
      }
    }
  }, [isConnected, reauthorizeNeeded]);

  const switchToBase = async () => {
    if (!walletProvider) return;
    
    try {
      await walletProvider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x2105' }], // 0x2105 is the hexadecimal representation of 8453
      });
    } catch (error) {
      console.error('Failed to switch network:', error);
    }
  };

  const toggleSteps = ()=>{
    setForceShowStep(true);
    setStep(1)
  }

  const changeStepWithScroll = () => {
    setStep(2);
    if(forceShowStep){
      setTimeout (()=>{
        formSection.current?.scrollIntoView({
          behavior: 'smooth'
        })
        setIsButtonShow(false)
      }, 300)
    }
  }

  const getNFTInfo = useCallback(async () => {
    if (!connectedContract || !address) return;
    try {
      setIsLoading(true)
      const tokens = await connectedContract.balanceOf(address);
      const tokenIds = [];
      for (let i = 0; i < tokens; i++) {
        const tokenId = await connectedContract.tokenOfOwnerByIndex(address, i);
        tokenIds.push(tokenId.toString());
      }
      setNftInfo({ tokens: tokens.toString(), tokenIds });
    } catch (error) {
      console.error('Failed to get NFT info:', error);
    } finally {setIsLoading(false)}
  }, [connectedContract])

  useEffect(()=>{
    const token = Cookies.get('jwtt');
    if(token) setJwtToken(token);
  },[])

  useEffect(()=>{
    authenticateUser()
  },[authenticateUser])

  useEffect(()=>{
    getNFTInfo();
  },[getNFTInfo])

  useEffect(()=>{
    const initContract = async () => {
      if (!walletProvider) return;
      setIsLoading(true)
      const provider = new BrowserProvider(walletProvider);
      const signer = await provider.getSigner();
      const contract = new Contract(CONTRACT_ADDRESS, PhygitalCollectionABI, signer);
      setConnectedContract(contract);

      // try {
      //   let price = await contract.getCurrentPriceInEth();
      //   setConnectedContract(contract);
      //   console.log('%c Contract initialized ', 'background: green; color: white');
      // } catch (error) {
      //   console.error('Failed to fetch price:', error);
      //   setErrorMessage('Failed to connect');
      //   setTimeout(initContract, 5000);
      // }

      
    };
    initContract();
    setIsLoading(false);
    if(!isConnected){
      setForceShowStep(false);
      setNftInfo(undefined);
    }
  },[isConnected, walletProvider, address])

  useEffect(()=>{
    if(nftInfo && + nftInfo.tokens > 0){
      setForceShowStep(false);
      setStep(2);
    }else if(!isConnected){
      setStep(1)
    }
  }, [nftInfo, isConnected]);

  useEffect(()=>{
    if(step === 3)window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [step])

  if(isLoading) return (
    <div className="App">
      <main style={{backgroundColor: 'rgb(15, 15, 15)'}}>
        {/* Main Page Content */}
        <motion.div
          className="content-full-width page-loader dark-section"
          animate={{ opacity: [0.3, 0.7, 0.3], scale: [1, 1.1, 1] }}
          transition={{ 
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        >
           <h2 className="primary-font-title">L O A D I N G</h2>
        </motion.div>
      </main>
    </div>
  )

  return (
    
    <div className="App">
      <main style={{backgroundColor: 'rgb(15, 15, 15)'}}>
        {/* Main Page Content */}
        <motion.div
          id="main-page-content"
          className="content-full-width"
          initial={{ opacity: 0 , scale: 1}}
          animate={{ opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.8 }}
        >
          <hr />
          <div className="dark-section-wrapper">
            <div className="dark-section-container content-max-width">
             {(step === 1 || forceShowStep || !jwtToken) && ( 
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ ease: "easeOut", duration: 0.8 }}
                >
                  <div className="content-row dark-section">
                    <h1 className="has-mask-fill primary-font-title">GET YOUR OG COLLECTION PACK</h1>
                  </div>
                  <div className="content-row dark-section reverse-on-mobile">
                    <div className="one_half">
                      <hr />
                      <hr />
                      <ol className='bigger no-margins unstyled-list-on-mobile'>
                        <li className="has-mask-fill">Phygital wallet with NFC chip</li>
                        <li>Digital twin NFT charged with <span className='text-green'>105 Fiji tokens</span></li>
                        <li>10% Boost NFT valid for 1 year</li>
                        <li>Early adopter achievement</li>
                      </ol>
                      <p>The mint is on the Base using ETH</p>
                      <hr />
                      {isConnected && chainId !== baseMainnet.chainId ? (
                        <MainButton
                          text="Switch to Base"
                          hoverText="Switch to Base Network"
                          handler={switchToBase}
                        />
                      ) : (
                        <MintNft 
                          connectedContract={connectedContract}
                          nftInfo={nftInfo}
                          getNFTInfo={getNFTInfo}
                        />
                      )}
                      <hr className='hidden-on-mobile'/>
                      <hr className='hidden-on-mobile'/>
                    </div>
                                        
                    <div className="one_half last vertical-parallax">                                
                        <motion.figure
                          initial={{ opacity: 0, scale: 0 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ ease: "easeOut", duration: 0.8, delay: 0.3 }}
                        >
                            <img src={packImage} alt="OG Collection Pack" className='mobile-width-3-5' />
                            {/* <figcaption>Phygital Miner</figcaption> */}
                        </motion.figure>                                    
                    </div>         
                                        
                  </div>
                 {isButtonShow && isConnected && +(nftInfo?.tokens ?? '0') > 0 && (
                  <div className="dark-section">
                    <MainButton
                      text={"Provide Your Address"}
                      hoverText={"Provide Your Address"}
                      handler={changeStepWithScroll}
                      // loadingText={"Working"}
                    />
                    <hr />
                    <hr />
                  </div>
                 )}
                </motion.div>
              )}
              {(step === 2 && jwtToken) && (
                <motion.section
                  ref={formSection}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ ease: "easeOut", duration: 0.8 }}
                >
                  <div className='dark-section'>
                    
                    <div className="content-row">
                      <h1 className="has-mask-fill primary-font-title">STEP 2: Provide your shipping Address</h1>
                    </div>
                    <small>Your got a pack #{nftInfo?.tokenIds[nftInfo?.tokenIds.length - 1]}</small>
                  </div>
                    
                  <div className="content-row dark-section">
                    <div className="one_half">
                      <ShippingAddressForm
                        nftInfo={nftInfo}
                        toggleSteps={toggleSteps}
                        forceShowStep={forceShowStep}
                        setForceShowStep={setForceShowStep}
                        jwtToken={jwtToken}
                        setStep={setStep}
                        setReauthorizeNeeded={setReauthorizeNeeded}
                      />
                    </div>
                    <div className="one_half last">
                    </div>
                  </div>
                </motion.section>
              )}
              {step === 3 && (
                <div className='max-vh'>
                  <Confetti recycle={false}/>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ ease: "easeOut", duration: 0.8 }}
                  >
                    <div className='dark-section'>
                        
                      <div className="content-row">
                        <h1 className="has-mask-fill primary-font-title">Congratulations</h1>
                      </div>
                      <p>Your pack #{nftInfo?.tokenIds[nftInfo?.tokenIds.length - 1]}</p>
                    </div>
                      
                    <div className="content-row dark-section">
                      <div className="one_half">
                        <p>
                        Great news! Your NFT pack has been secured, and the associated physical item will be delivered to the address you provided. 😊 
                        </p>
                        <p>What to expect next:</p>
                        <ul>
                          <li>Within a week, we'll take a snapshot for your NFT.</li>
                          <li>Your physical item will be delivered to your provided address.</li>
                          <li>You will receive your tracking number by email when the item is ready for shipment. Keep an eye on your inbox! 📦📧</li>
                        </ul>
                        <p>
                        Once your physical item arrives, you'll be able to mint your boost and achievement badge by scanning the embedded NFC chip. Get ready for an exciting unboxing experience! 🎉😃
                        </p>
                        <p>
                        Exciting update: Your FIJI tokens mining has already begun! 🚀💰
                        </p>
                        <p>Please join Telegram support group if you have any questions</p>
                        
                        <TgButton text="Join Support Group" url="https://t.me/+F7J61tj7sWIyZmIy" icon={true}/>
                        
                        <div className="content-row content-to-bottom">
                        
                            <MainButton
                              text={"Change address"}
                              hoverText={"Change address"}
                              handler={()=>{setStep(2)}}
                              // loadingText={"Working"}
                            />
                            <MainButton
                              text={"Mint more"}
                              hoverText={"Mint more"}
                              handler={()=>{setStep(1)}}
                              // loadingText={"Working"}
                            />
                        </div>
                        <hr />
                        
                        
                        
                      </div>
                      <div className="one_half last">
                      </div>
                    </div>
                  </motion.div>
                </div>
              )}
            </div>
          </div>
        </motion.div>
      </main>
    </div>
    
  );
}

export default App;
