import { useTsController } from "@ts-react/form";

interface Props {
  options: string[],
  defaultValue?: string
}

export function FormSelect(props: Props) {
  const {options, defaultValue} = props
  const { field, error } = useTsController<string>();
  return (
    <>
      <div className="input-box">
        <select
          value={field.value ? field.value : (defaultValue || "none")}
          onChange={(e) => {
            field.onChange(e.target.value);
          }}
        >
          {!field.value && <option value="none">Please select {field.name}</option>}
          {options.map((e) => (
            <option key={e} value={e}>
              {e}
            </option>
          ))}
        </select>
        <div className="alert-error">{error?.errorMessage && error.errorMessage}</div>
      </div>
    </>
  );
}